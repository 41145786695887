import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        desktopUrl: String,
        mobileUrl: String,
    }

    connect() {
        this.loadHiResImages();
        window.onresize = this.loadHiResImages.bind(this);
    }

    loadHiResImages(){
        if(window.innerWidth > 768) {
            this.loadHiResImage(this.desktopUrlValue, this.setBackgroundImage.bind(this));
        }else{
            this.loadHiResImage(this.mobileUrlValue, this.setBackgroundImage.bind(this));
        }
    }
    
    loadHiResImage(src, callback) {
        const img = new Image();
        img.onload = () => callback(src);
        img.src = src;
    }

    setBackgroundImage(src) {
        this.element.style.backgroundImage = `url('${src}')`;
    }
}