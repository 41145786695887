import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["banner", "container", 'form', 'cookiesSelection', 'analyticsFrame'];

    connect() {
        let cookiesAcceptedAt = this.getCookie('hv_cookies_accepted_at');
        let acceptedCookies = this.getCookie('hv_cookies_accepted');
        if (cookiesAcceptedAt === null) {
            this.showCookieBanner();
        }
    }

    accept(acceptedCookies) {
        const acceptedAt = new Date().toISOString();
        // Set browser cookie with 1 year expiry
        const expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        document.cookie = `hv_cookies_accepted=${acceptedCookies};expires=${expiryDate.toUTCString()};path=/`;
        document.cookie = `hv_cookies_accepted_at=${acceptedAt};expires=${expiryDate.toUTCString()};path=/`;

        // Hide banner and reload analytics frame

        this.hideCookieBanner();
        if (this.hasAnalyticsFrameTarget) {
            this.analyticsFrameTarget.reload();
        }
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    }

    acceptEssential() {
        this.accept('essential');
    }

    acceptAll() {
        this.accept('all');
    }

    showCookieBanner() {
        this.containerTarget.style.display = "block";
        this.bannerTarget.style.display = "block";
    }

    hideCookieBanner() {
        this.bannerTarget.style.display = "none";
        this.containerTarget.style.display = "none";
    }
}
