import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        quality_src: String
    }

    connect() {
        if (this.hasQualitySrcValue) {
            this.loadHiResImage(this.qualitySrcValue, this.updateCurrentImage.bind(this));
        }
    }

    updateCurrentImage(img) {
        this.element.replaceWith(img);
    }

    loadHiResImage(src, callback) {
        const clone = new Image();
        this.element.classList.forEach((className) => {
            clone.classList.add(className);
        });
        clone.style = this.element.style;
        clone.alt = this.element.alt;
        clone.title = this.element.title;
        clone.onload = () => callback(clone);
        clone.src = src;
    }
}