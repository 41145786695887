import '@hotwired/turbo-rails'
import {Collapse} from "flowbite";

import {application} from "./controllers/application"

// import TurboVisitorController from "./controllers/turbo_visitor_controller.js"
// application.register("turbo-visitor", TurboVisitorController)

import LandingNavbarController from "./controllers/tailwind/landing_navbar_controller.js"
application.register("landing-navbar", LandingNavbarController)

import LandingHeroController from "./controllers/tailwind/landing_hero_controller.js"
application.register("landing-hero", LandingHeroController)

import ProgressiveImageController from "./controllers/assets/progressive_image_controller"
application.register("progressive-image", ProgressiveImageController)

import CookieBannerController from "./controllers/cookie_banner_controller"
application.register("cookie-banner", CookieBannerController)

window.document.addEventListener("turbo:render", (_event) => {
    window.initFlowbite();
});